* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: Arial, sans-serif;
}

.container {
  display: flex;
  height: 100vh;
  overflow: hidden;
  flex-direction: row;
}

.left-side {
  width: 40%;
  background-color: #1d705a;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.right-side {
  width: 60%;
  padding: 20px;
  background-color: #fefefa;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.right-side-content {
  padding: 20px;
}

.name-title {
  margin-bottom: 50px;
}

.contact-info {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 50%;
}

.contact-item {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
}

.contact-item img {
  width: 38px;
  height: 38px;
  margin-right: 15px;
}

.contact-item span {
  color: white;
}

.button-details {
  width: 60%;
}

section {
  margin-bottom: 20px;
}

.section-title {
  background-color: #1d705a;
  color: white;
  padding: 8px 12px;
  margin-bottom: 15px;
  display: inline-block;
  border-radius: 4px;
}

.timeline {
  display: flex;
  flex-direction: column;
  position: relative;
  margin-left: 20px;
}

.timeline-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
  position: relative;
}

.dot-line {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 10px;
}

.dot {
  width: 12px;
  height: 12px;
  background-color: #1d705a;
  border-radius: 50%;
  margin-top: 5px;
}

.timeline-content {
  display: flex;
  flex-direction: column;
}

.institution-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}

.degree-title,
.degree-field,
.degree-dates {
  font-size: 1.1rem;
  margin: 0;
  padding-top: 5px;
}

.course-list {
  margin-top: 10px;
}

.course-list p {
  font-size: 1rem;
  margin: 2px 0;
}

.project-name {
  font-size: 1.3rem;
  font-weight: bold;
  margin: 0;
}

.project-description {
  font-size: 1.1rem;
  margin: 0;
  padding-top: 5px;
}

.project-list {
  margin-top: 10px;
}

.project-list p {
  font-size: 1rem;
  margin: 2px 0;
}

.skills {
  margin-top: 15px;
  padding-bottom: 15px;
}

.skill {
  display: flex;
  align-items: center;
  margin-bottom: 15px;
  padding-left: 25px;
}

.skill span {
  width: 140px;
  font-weight: bold;
  margin-right: 10px;
  color: #333;
}

.progress-bar {
  width: 60%;
  background-color: #ddd;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  height: 12px;
}

.progress-bar::before {
  content: "";
  display: block;
  height: 100%;
  background-color: #1d705a;
  width: 0;
  border-radius: 8px;
  transition: width 0.5s ease-in-out;
}

.progress-bar[data-level="90"]::before {
  width: 90%;
}

.progress-bar[data-level="80"]::before {
  width: 80%;
}

.progress-bar[data-level="70"]::before {
  width: 70%;
}

.progress-bar[data-level="60"]::before {
  width: 60%;
}

.progress-bar[data-level="50"]::before {
  width: 50%;
}

.language-table {
  width: 90%;
  border-collapse: collapse;
  margin-top: 10px;
}

.language-table th,
.language-table td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

.language-table th {
  background-color: #1d705a;
  font-weight: bold;
  color: white;
}

.language-table td[data-language="English"] {
  background-color: #2a9e7f;
  color: white;
  font-weight: bold;
}

.language-table td[data-language="Spanish"] {
  background-color: #2a9e7f;
  color: white;
  font-weight: bold;
}

.language-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.language-table tr:hover {
  background-color: #edf5ee;
}

.language-table td {
  font-size: 0.95rem;
}

.diss-title {
  font-size: 1.8rem;
  text-align: center;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

.project-list ul {
  padding-left: 20px;
  margin-top: 5px;
}

.project-list li {
  font-size: 1rem;
  line-height: 1.6;
  margin-bottom: 5px;
}

.project-list ul li::marker {
  color: #2a9e7f;
}

.each-slide-effect {
  width: 100%;
}

.each-slide-effect>div {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.each-slide-effect>div img {
  width: 100%;
  height: auto;
  max-height: 800px;
  display: block;
  object-fit: cover;
}

.mobile-image {
  width: auto !important;
  height: 100% !important;
  object-fit: contain !important;
}


/* Mobile Version */
@media (max-width: 768px) {
  .container {
    flex-direction: column;
  }

  .left-side {
    width: 100%;
    height: 20%;
    padding: 10px;
  }

  .right-side {
    width: 100%;
    padding: 10px;
  }

  .name-title {
    margin-bottom: 10px !important;
  }

  .contact-info {
    display: flex;
    flex-direction: row !important;
    justify-content: space-around;
    align-items: center;
    width: 100% !important;
    text-align: center;
  }

  .contact-item {
    margin: 0;
    align-items: center;
  }

  .contact-item img {
    margin: 0px !important;
  }

  .contact-item span {
    display: none;
  }

  .button-details {
    width: 60%;
  }

  .language-table,
  .language-table thead,
  .language-table tbody,
  .language-table th,
  .language-table td,
  .language-table tr {
    display: block;
    width: 100%;
  }

  .language-table thead tr {
    display: none;
  }

  .language-table tbody tr {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .language-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: bold;
  }

  .language-table td::before {
    content: attr(data-label);
    font-weight: normal;
    color: #333;
  }

  .language-table td[data-language="English"]::before {
    content: "Language: ";
    color: white;
  }

  .language-table td[data-language="Spanish"]::before {
    content: "Language: ";
    color: white;
  }

  .language-table td:nth-child(2)::before {
    content: "Listening: ";
  }

  .language-table td:nth-child(3)::before {
    content: "Reading: ";
  }

  .language-table td:nth-child(4)::before {
    content: "Spoken interaction: ";
  }

  .language-table td:nth-child(5)::before {
    content: "Spoken production: ";
  }

  .language-table td:nth-child(6)::before {
    content: "Writing: ";
  }
}

/* Smaller Screen Version */
@media (max-width: 1200px) {

  .language-table,
  .language-table thead,
  .language-table tbody,
  .language-table th,
  .language-table td,
  .language-table tr {
    display: block;
    width: 100%;
  }

  .language-table thead tr {
    display: none;
  }

  .language-table tbody tr {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: 10px;
    border: 1px solid #ddd;
    padding: 10px;
  }

  .language-table td {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    font-weight: bold;
  }

  .language-table td::before {
    content: attr(data-label);
    font-weight: normal;
    color: #333;
  }

  .language-table td[data-language="English"]::before {
    content: "Language: ";
    color: white;
  }

  .language-table td[data-language="Spanish"]::before {
    content: "Language: ";
    color: white;
  }

  .language-table td:nth-child(2)::before {
    content: "Listening: ";
  }

  .language-table td:nth-child(3)::before {
    content: "Reading: ";
  }

  .language-table td:nth-child(4)::before {
    content: "Spoken interaction: ";
  }

  .language-table td:nth-child(5)::before {
    content: "Spoken production: ";
  }

  .language-table td:nth-child(6)::before {
    content: "Writing: ";
  }
}